import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate,Link } from 'react-router-dom';
import Layout from './Layout';
import ReCAPTCHA from "react-google-recaptcha";

const Login = ({ onLogin }) => {
    const [values, setValues] = useState({ username: '', password: '' });
    const [loading, setLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [capVal, setCapVal] = useState(null);
    const [siteKey, setSiteKey] = useState('');

    useEffect(() => {

        const fetchConfig = async () => {
            try {
                const response = await fetch('https://radcliffe-ndt.com/api/config');
                const data = await response.json();
                setSiteKey(data.siteKey);
            } catch (error) {
                console.error('Error fetching config:', error);
            }
        };

        fetchConfig();
    }, []);

    const handleInput = (event) => {
        setValues((prev) => ({
            ...prev,
            [event.target.name === 'name' ? 'username' : event.target.name]: event.target.value,
        }));
    };

    const handleLogin = (token) => {
        const { authToken, username, claimType } = token;

        localStorage.setItem('token', JSON.stringify(authToken));
        localStorage.setItem('username', username);
         if (claimType) {
            localStorage.setItem('claimType', JSON.stringify(claimType));
        }


        setIsLoggedIn(true);

        if (claimType && claimType.includes('admin')) {
            navigate('/UserManagement');
        } else if (claimType && claimType.includes('employee')) {
            navigate('/D11Calculator');
        } else {
            console.error('Token, username, or claimType is missing.');
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            if (!capVal) {
                console.error('reCAPTCHA validation failed.');
                return;

            }
            const res = await axios.post('https://radcliffe-ndt.com/api/login', values);

            const { token, expiresIn } = res.data;
            const username = values.username;
            const userResponse = await axios.get('https://radcliffe-ndt.com/api/user-info', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            
            
            const { claimType } = userResponse.data;

            
            localStorage.setItem('token', JSON.stringify(token));
            localStorage.setItem('username', username);
            localStorage.setItem('claimType', JSON.stringify(claimType));
            localStorage.setItem('tokenExpiration', expiresIn.toString());

            
            handleLogin({ authToken: token, username, claimType });

             if (claimType && claimType.includes('admin')) {
                navigate('/D11Calculator');
            } else if (claimType && claimType.includes('employee')) {
                navigate('/D11Calculator');
            } else {
               navigate('/');
            }
        } catch (error) {
            console.error('Login failed:', error);
            if (error.response) {
               
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
            } else if (error.request) {
               console.error('No response received:', error.request);
            } else {
              console.error('Error setting up the request:', error.message);
            }

        } finally {
            setLoading(false);
        }
    };



    useEffect(() => {
         const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
        }
    }, []);

    
    return (
        <Layout>
            <header style={{ backgroundColor: '#333', color: '#fff', textAlign: 'center', padding: '1em', marginTop: '0px' }}>
                <h1>LOGIN</h1>
                
            </header>
            <section style={{ maxWidth: '800px', margin: '20px auto', padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        
                
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="name">
                            <strong>Username</strong>
                        </label>
                        <input
                            type="text"
                            placeholder="Enter Username"
                            name="username"
                            onChange={handleInput}
                            className="form-control rounded-0"
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password">
                            <strong>Password</strong>
                        </label>
                        <input
                            type="password"
                            placeholder="Enter Password"
                            name="password"
                            onChange={handleInput}
                            className="form-control rounded-0"
                        />
                    </div>
                    <div>
                        {siteKey && (
                            <ReCAPTCHA
                                sitekey={siteKey}
                                onChange={(val) => setCapVal(val)}
                            />
                        )}
                    </div>
                    <button type="submit" className="btn btn-success w-100 rounded-0" disabled={loading || !capVal}>
                        {loading ? 'Logging in...' : 'Log in'}
                    </button>
                    <p></p>
                    <Link to="/UserManagement">User Management</Link>
                </form>
               
            
            </section>
        </Layout>
    );
};

export default Login;
