// UserManagement.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import Layout from './Layout';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('https://radcliffe-ndt.com/api/users');
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []); 

    return (
        <Layout>
            <header style={{ backgroundColor: '#333', color: '#fff', textAlign: 'center', padding: '1em', marginTop: '0px' }}>
                <h1>User Management</h1>
            </header>

            <section style={{ maxWidth: '1200px', margin: '20px auto', padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', textAlign: 'left' }}>
            <div>
                <Link to="/CreateUser" className="user-management-link">Add New User</Link>
                <p></p>
            </div>
            {loading ? (
                <p>Loading users...</p>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Username</th>
                            <th>Claim Type</th>
                            <th>Company</th>
                            <th>Department</th>
                            <th>Employee Number</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user.Id}>
                                <td>{user.Id}</td>
                                <td>{user.UserName}</td>
                                <td>{user.ClaimType}</td>
                                <td>{user.Company}</td>
                                <td>{user.Department}</td>
                                <td>{user.EmployeeNumber}</td>
                                <td><Link to={`/ModifyUser/${user.Id}`}>Edit</Link>

                                    {' '}
                                    |{' '}
                                    <Link to={`/DeleteUser/${user.Id}`}>Delete</Link></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            </section>
            </Layout>
    );
};

export default UserManagement;
