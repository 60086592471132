import './App.css'
import Welcome from './Welcome.jsx';
import Services from './Services.jsx';
function App() {
    return (
       
        <div>
          <Welcome /> 
        </div>
           
           );
}

export default App
