import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import App from './App';
import Services from './Services';
import ContactUs from './ContactUs';
import D11Calculator from './D11Calculator';
import MultipleDecision from './MultipleDecision';
import Login from './Login';
import UserManagement from './UserManagement';
import CreateUser from './CreateUser';
import ModifyUser from './ModifyUser';
import DeleteUser from './DeleteUser';
import UnauthorizedPage from './UnauthorizedPage';
import Success from './Success';
import Error from './Error';

const RedirectToLogin = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/Login');
    }, [navigate]);

    
    return null;
};

const ProtectedRoute = ({ element, requiredClaims }) => {
    const [userClaims, setUserClaims] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthAndAuthorize = async (requiredClaims) => {
            try {
               
                const isLoggedIn = localStorage.getItem('token') !== null;

                if (!isLoggedIn) {
                    console.error('User is not logged in.');
                    navigate('/Login'); 
                    return;
                }

                console.log('Required Claims:', requiredClaims);

                if (!Array.isArray(requiredClaims) || requiredClaims.length === 0) {
                    console.error('Invalid or missing required claims.');
                    
                    navigate('/Login');
                    return;
                }

                const claimsFromStorage = localStorage.getItem('claimType');

                if (!claimsFromStorage) {
                    console.error('Claims are not available.');
                    navigate('/Login'); 
                    return;
                }

                const parsedClaims = JSON.parse(claimsFromStorage);

                console.log('Parsed Claims:', parsedClaims);

                if (!parsedClaims || typeof parsedClaims !== 'string') {
                    console.error('Invalid claims format.');
                    navigate('/Login'); 
                    return;
                }

                setUserClaims(parsedClaims);

                
                const parsedClaimsArray = typeof parsedClaims === 'string' ? [parsedClaims] : parsedClaims;
                const hasRequiredClaims = requiredClaims.some((claim) => parsedClaimsArray.includes(claim));
               

                if (!hasRequiredClaims) {
                   
                    navigate('/Unauthorized');
                }
            } catch (error) {
                console.error('Error during authentication and authorization:', error);
                navigate('/Login');
            } finally {
                setLoading(false);
            }
        };

        checkAuthAndAuthorize(requiredClaims);
    }, [requiredClaims, navigate]);


    if (loading) {
        return <div>Loading...</div>;
    }

    
    return React.cloneElement(element, { onLogin: () => navigate('/Login') });
};



const AppRouter = () => {
    

   

    return (
        <Router>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/Services" element={<Services />} />
                <Route path="/ContactUs" element={<ContactUs />} />
                <Route
                    path="/D11Calculator"
                    element={<ProtectedRoute element={<D11Calculator />} requiredClaims={['employee', 'admin']} />}
                />
                <Route
                    path="/MultipleDecision"
                    element={<ProtectedRoute element={<MultipleDecision />} requiredClaims={['employee', 'admin']} />}
                />
                <Route path="/Login" element={<Login />} />
                <Route
                    path="/UserManagement"
                    element={<ProtectedRoute element={<UserManagement />} requiredClaims={['admin']} />}
                />
                <Route
                    path="/CreateUser"
                    element={<ProtectedRoute element={<CreateUser />} requiredClaims={['admin']} />}
                />
                <Route
                    path="/ModifyUser/:userId"
                    element={<ProtectedRoute element={<ModifyUser />} requiredClaims={['admin']} />}
                />
                <Route
                    path="/DeleteUser/:userId"
                    element={<ProtectedRoute element={<DeleteUser />} requiredClaims={['admin']} />}
                />
                <Route path="/Unauthorized" element={<UnauthorizedPage />} />
                <Route path="/Success" element={<Success />} />
                <Route path="/Error" element={<Error />} />
            </Routes>
        </Router>
    );
};

export default AppRouter;
