import React from 'react';
import Layout from './Layout';

function Success() {
    return (
        <Layout>
            <header style={{ backgroundColor: '#333', color: '#fff', textAlign: 'center', padding: '1em', marginTop: '0px' }}>
                <h1>Contact Form Success</h1>
            </header>

            <section style={{ maxWidth: '800px', margin: '20px auto', padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', textAlign: 'left' }}>

                <h2>Form Submitted Successfully!</h2>
                <p>We will be reaching out to you shortly.</p>
            </section>

        </Layout>
    );
}


export default Success;