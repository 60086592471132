import React, { useState, useEffect } from "react";
import Layout from './Layout';
import { useNavigate } from 'react-router-dom';



const D11Calculator = () => {
    const [thickness, setThickness] = useState('');
    const [unit, setUnit] = useState('inches');
    const [wedgeAngle, setWedgeAngle] = useState('');
    const [codeSelect, setCodeSelect] = useState('');
    const [utSelect, setUtSelect] = useState('');
    const [message, setMessage] = useState('');
    const [result, setResult] = useState('');
    const [errors, setErrors] = useState({
        thickness: '',
        wedgeAngle: '',
        codeSelect: '',
        UTSelect: '',
    });
    const [calculationResults, setCalculationResults] = useState({
        skipDistance: null,
        thicknessLookupResult: null,
    });

    const [additionalFieldsVisible, setAdditionalFieldsVisible] = useState(false);
    const [a, setA] = useState('');
    const [b, setB] = useState('');
    const [c, setC] = useState('');
    const [d, setD] = useState('');

    const [attenuationRating, setAttenuationRating] = useState(null);
    const [calculatedD, setCalculatedD] = useState(null);

    const [roundedD, setRoundedD] = useState('');
    const [roundedDUpdated, setRoundedDUpdated] = useState(false);
    const [buttonPressed, setButtonPressed] = useState(false);
    const [indicationLength, setIndicationLength] = useState('');
    const [dynamicValue, setDynamicValue] = useState("");
    const [enterPressed, setEnterPressed] = useState(false);
    const [isPrimaryTensileStressYes, setIsPrimaryTensileStressYes] = useState(false);
    const [multipleIndication, setMultipleIndication] = useState('');
    const navigate = useNavigate();
    const [decisionResult, setDecisionResult] = useState('');
   
    const fetchData = async () => {
        try {
            const lookupResult = await performLookup();
            const expressionResult = await calculateExpression();
            const attenuationResult = await calculateAttenuation();
            const calcSeverityResult = await calculateSeverity(lookupResult.result);

            updateState({
                skipDistance: expressionResult.result,
                thicknessLookupResult: lookupResult.result,
                attenuationRating: attenuationResult.attenuationRating,
                calculatedD: attenuationResult.calculatedD,
                severityClassResult: calcSeverityResult.result,
            });

            setResult(`${expressionResult.result}`);
            if (a && b && c) {
                setAttenuationRating(attenuationResult.attenuationRating);
                setCalculatedD(attenuationResult.calculatedD);
            }

            setRoundedDUpdated(false);
        } catch (error) {
            console.error('Error performing calculations:', error);
            console.error(error.stack);
          
        }
    };


    const performLookup = async () => {
        const lookupResponse = await fetch('https://radcliffe-ndt.com/api/performLookup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                inputValue: thickness,
                unit,
            }),
        });

        return await lookupResponse.json();
    };

    const calculateExpression = async () => {
        const expressionResponse = await fetch('https://radcliffe-ndt.com/api/calculateExpression', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                thickness,
                wedgeAngle,
                unit,
            }),
        });

        return await expressionResponse.json();
    };

    const calculateAttenuation = async () => {
        const attenuationResponse = await fetch('https://radcliffe-ndt.com/api/calculateAttenuation', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                c,
                unit,
                a,
                b,
            }),
        });

        if (!attenuationResponse.ok) {
            throw new Error(`HTTP error! Status: ${attenuationResponse.status}`);
        }

        return await attenuationResponse.json();
    };

    const calculateSeverity = async (thicknessResult) => {
        const calcSeverityResponse = await fetch('https://radcliffe-ndt.com/api/calcSeverity', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                selectedD1Table: codeSelect,
                unit,
                wedgeAngle: parseFloat(wedgeAngle),
                thicknessResult,
                roundedD,
            }),
        });

        if (!calcSeverityResponse.ok) {
            throw new Error(`HTTP error! Status: ${calcSeverityResponse.status}`);
        }

        return await calcSeverityResponse.json();
    };

    const fetchDecision = async () => {
        try {
            const isPrimaryTensileStressSelect = document.getElementById("IsPrimaryTensileStress");
            const isPrimaryTensileStressValue = isPrimaryTensileStressSelect ? isPrimaryTensileStressSelect.value : '';

            console.log("Values being sent to API:", {
                SeverityClassResult: calculationResults.severityClassResult,
                IndicationLength: indicationLength,
                MultipleIndication: multipleIndication,
                IsPrimaryTensileStress: isPrimaryTensileStressValue,
                Unit: unit,
            });
            const decisionResponse = await fetch('https://radcliffe-ndt.com/api/getDecision', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    SeverityClassResult: calculationResults.severityClassResult,
                    IndicationLength: indicationLength,
                    MultipleIndication: multipleIndication,
                    IsPrimaryTensileStress: isPrimaryTensileStressValue, 
                    Unit: unit, 
                }),
            });

            if (!decisionResponse.ok) {
                throw new Error(`HTTP error! Status: ${decisionResponse.status}`);
            }

            const decisionResult = await decisionResponse.json();
            setDecisionResult(decisionResult.result);
        } catch (error) {
            console.error('Error fetching decision:', error);
          
        }
    };

    const updateState = (values) => {
        setCalculationResults((prevResults) => ({
            ...prevResults,
            ...values,
        }));
    };

   
    useEffect(() => {
        const fetchDataIfUpdated = async () => {
            if (roundedDUpdated) {
                try {
                    
                    setRoundedD('');
                    updateState({
                        severityClassResult: '',
                    });

                    
                    const calcSeverityResult = await calculateSeverity(
                        utSelect === 'N' ? calculationResults.thicknessLookupResult : thickness
                    );

                    
                    updateState({
                        severityClassResult: calcSeverityResult.result,
                    });

                    setRoundedDUpdated(false); 
                } catch (error) {
                    console.error('Error updating severity class:', error);
                   
                }
            }
        };

        fetchDataIfUpdated();
    }, [roundedD, roundedDUpdated, utSelect, calculationResults.thicknessLookupResult, thickness]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonPressed(true);
        const newErrors = {};

        if (thickness === '') {
            newErrors.thickness = 'Thickness value is required.';
        } else {
            const numericThickness = parseFloat(thickness);

            if ((unit === 'inches' && numericThickness < 0.3125) || (unit === 'millimeter' && numericThickness < 8.0)) {
                newErrors.thickness = 'Minimum thickness requirement not met. 5/16 (0.3125) inches or 8 mm is the minimum thickness.';
            }
        }

        if (wedgeAngle === '') {
            newErrors.wedgeAngle = 'Please select a wedge angle value.';
        }

        if (codeSelect === '') {
            newErrors.codeSelect = 'Please select an AWS D1.1 Table. Code Reference.';
        }

        if (utSelect === '') {
            newErrors.UTSelect = 'Please select if your machine has a Built-in D1.1 Function.';
        }



        setErrors(newErrors);

       
        if (Object.keys(newErrors).length === 0) {
            try {
                let newD;

                if (utSelect === 'N') {
                    
                    if (buttonPressed && (!a || !b || !c)) {
                        newErrors.UTSelect = 'Please enter scanning level, reference level, and sound path distance.';
                        setErrors(newErrors);
                        return; 
                    }
                    
                    const attenuationResult = await calculateAttenuation();
                    newD = attenuationResult.calculatedD;
                } else if (utSelect === 'Y') {
                    if (!d) {
                        newErrors.UTSelect = 'Please enter the d value from UT machine AWS D1.1 module.';
                        setErrors(newErrors);
                        return;
                    }
                    newD = d;
                }

                setD(newD);

                const roundedD = Math.ceil(newD);
                setRoundedD(roundedD);
                setRoundedDUpdated(true);

                
                if (utSelect === 'N') {
                    await fetchData();
                }

            } catch (error) {
                console.error('Error performing calculations:', error);
                console.error(error.stack);
                
            }

            setAdditionalFieldsVisible(utSelect === 'N');
        }
    };

    useEffect(() => {
        
        let newMessage = '';
        let newResult = ''; 

        if ((wedgeAngle === '45' || wedgeAngle === '60') && (
            (unit === 'inches' && parseFloat(thickness) <= 1.5) || (unit === 'millimeter' && parseFloat(thickness) <= 38)
        )) {
            newMessage = '45 and 60 degree wedge minimum thickness has to be greater than 1.5 inches or 38 mm to use the severity class calculator.';
        } else {
            newMessage = '';
            // If 'N' is selected, show the additional fields
            setAdditionalFieldsVisible(utSelect === 'N');

        }

        setMessage(newMessage);

        const resultValue = ``;
        setResult(resultValue);

        setAdditionalFieldsVisible(utSelect === 'N');
    }, [wedgeAngle, thickness, unit, utSelect]);

    useEffect(() => {
        
        const fetchDataIfUpdated = async () => {
            if (roundedDUpdated) {
                await fetchData();
            }
        };

        fetchDataIfUpdated();
    }, [roundedDUpdated]);

    useEffect(() => {
        
          
        var indicationLengthInput = document.getElementById("IndicationLength");

        if (indicationLengthInput) {
            indicationLengthInput.addEventListener("input", function () {
                var inputLength = parseFloat(this.value);
                if (!isNaN(inputLength)) {
                    
                    setIndicationLength(this.value);
                   
                    setEnterPressed(false);
                } else {
                   
                    setIndicationLength('');
                    setDynamicValue('');
                }
            });

            indicationLengthInput.addEventListener("keydown", function (event) {
                if (event.key === "Enter") {
                    setEnterPressed(true);
                }
            });
        }
    }, []); 

    useEffect(() => {
        if (enterPressed && indicationLength) {
           
            setDynamicValue((2 * parseFloat(indicationLength)).toFixed(2));
        } else {
           
            setDynamicValue('');
        }
    }, [enterPressed, indicationLength]);

    useEffect(() => {
        
        
        var isPrimaryTensileStressSelect = document.getElementById("IsPrimaryTensileStress");
               
        const handleIsPrimaryTensileStressChange = () => {
            const selectedValue = isPrimaryTensileStressSelect.value;

            setIsPrimaryTensileStressYes(selectedValue === "No");
           
        };
        if (isPrimaryTensileStressSelect) {
            isPrimaryTensileStressSelect.addEventListener("change", handleIsPrimaryTensileStressChange);
        }

        
        return () => {
            if (isPrimaryTensileStressSelect) {
                isPrimaryTensileStressSelect.removeEventListener("change", handleIsPrimaryTensileStressChange);
            }
        };

    }, [isPrimaryTensileStressYes, indicationLength]);


    useEffect(() => {
        
          
           
        var multipleIndicationSelect = document.getElementById("MultipleIndication");

        const handleMultipleIndicationChange = () => {
            if (multipleIndicationSelect.value === "Yes") {
                setMultipleIndication("Yes");
            } else {
                 setMultipleIndication("No");
            }
        };

        if (multipleIndicationSelect) {
            multipleIndicationSelect.addEventListener("change", handleMultipleIndicationChange);
        }

      
        return () => {
            if (multipleIndicationSelect) {
                multipleIndicationSelect.removeEventListener("change", handleMultipleIndicationChange);
            }
        };
    }, [setMultipleIndication]); 

    useEffect(() => {
        
        if (calculationResults.severityClassResult) {
            fetchDecision();
        }
    }, [calculationResults.severityClassResult, indicationLength, multipleIndication, isPrimaryTensileStressYes, unit]);



    return (
        <Layout>

            <style>
                {`
            label {
              font-weight: bold;
              text-decoration: underline;
            }
          `}
            </style>


            <header style={{ backgroundColor: '#333', color: '#fff', textAlign: 'center', padding: '1em', marginTop: '0px' }}>
                <h1>NDT AWS D1.1 UT Calculator</h1>
                <p>Use this page for AWS D1.1 Ultrasound Table Acceptance / Rejection Criteria.</p>
            </header>

            <section style={{ maxWidth: '800px', margin: '10px auto', padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <form onSubmit={handleSubmit}>
                  
                    <div className="form-group row">
                        <div className="col-4">
                            <label>Material Thickness:</label>
                        </div>

                        <div className="col-4">
                            <input type="number" value={thickness} onChange={(e) => setThickness(e.target.value)} />
                            <label>
                                <input type="radio" value="inches" checked={unit === 'inches'} onChange={() => setUnit('inches')} /> Inches
                            </label>

                            <label style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <input type="radio" value="millimeter" checked={unit === 'millimeter'} onChange={() => setUnit('millimeter')} /> Millimeter
                            </label>
                            <span style={{ color: 'red' }}>{errors.thickness}</span>
                            <p></p>
                        </div>
                    </div>

                    <p></p>
                   
                    <div className="form-group row" >
                        <div className="col-4">
                            <label>Wedge Angle:</label>
                        </div>
                        <div className="col-4">
                            <select value={wedgeAngle} onChange={(e) => setWedgeAngle(e.target.value)} className="form-control"
                                style={{ width: '300px' }}>
                                <option value="">-- Select wedge angle --</option>
                                <option value="45">45 degree</option>
                                <option value="60">60 degree</option>
                                <option value="70">70 degree</option>
                            </select>
                            <span style={{ color: 'red' }}>{errors.wedgeAngle}</span>
                            <p></p>
                        </div>
                    </div>

                   
                    <div className="form-group row">
                        <div className="col-4">
                            <label>AWS D1.1 Table:</label>
                        </div>
                        <div className="col-4">
                            <select value={codeSelect} onChange={(e) => setCodeSelect(e.target.value)} className="form-control"
                                style={{ width: '300px' }}>
                                <option value="">-- Select AWS D1.1Table --</option>
                                <option value="8.2">8.2-Statically Loaded (Fixed or constant load)</option>
                                <option value="8.3">8.3-Cyclically Loaded (continuous  & repeated application of load / fluctuating Stress).</option>
                            </select>
                            <span style={{ color: 'red' }}>{errors.codeSelect}</span>
                            <p></p>
                        </div>
                    </div>

                   
                    <div className="form-group row">
                        <div className="col-4">
                            <label>Built-in D1.1 Function:</label>
                        </div>
                        <div className="col-4">
                            <select value={utSelect} onChange={(e) => setUtSelect(e.target.value)} className="form-control"
                                style={{ width: '300px' }}>
                                <option value=""></option>
                                <option value="Y">YES</option>
                                <option value="N">NO</option>
                            </select>
                            <span style={{ color: 'red' }}>{errors.UTSelect}</span>
                            <p id="message"></p>
                        </div>
                    </div>


                    <p></p>
                  

                    {additionalFieldsVisible && (
                        <div>
                            
                            {result && (
                                <div>
                                    <p>Attenuation Rating: {attenuationRating}</p>
                                    <p>d-Value : {calculatedD}</p>

                                </div>
                            )}
                        </div>
                    )}

                   
                    {utSelect === 'Y' && (
                        <div className="form-group row">
                            <div className="col-4">
                                <span className="input-group-text" id="d-value">d-Value:</span>
                            </div>
                            <div className="col-4">
                                <input
                                    type="number"
                                    step="1"
                                    className="form-control"
                                    aria-describedby="d-value"
                                    value={d}
                                    onChange={(e) => setD(e.target.value)}
                                />
                            </div>
                        </div>
                    )}

                    
                    {additionalFieldsVisible && (
                        <div>
                            
                            <div className="text-danger" asp-validation-summary="All"></div>

                            <div className="input-group flex-nowrap" id="additionalFieldsAB">
                                <span className="input-group-text" id="a-addon">a-scanning level:</span>
                                <input
                                    type="number"
                                    step="0.1"
                                    min="1"
                                    className="form-control"
                                    aria-describedby="a-addon"
                                    value={a}
                                    onChange={(e) => setA(e.target.value)}
                               
                                />

                                <span className="input-group-text" id="b-addon">b-reference level:</span>
                                <input
                                    type="number"
                                    step="0.1"
                                    min="1"
                                    className="form-control"
                                    aria-describedby="b-addon"
                                    value={b}
                                    onChange={(e) => setB(e.target.value)}
                                
                                />

                                <span className="input-group-text" id="c-addon">SP-Sound Path to Indication:</span>
                                <input
                                    type="number"
                                    step="0.1"
                                    min="0"
                                    className="form-control"
                                    aria-describedby="c-addon"
                                    value={c}
                                    onChange={(e) => setC(e.target.value)}
                                
                                />
                            </div>
                        </div>
                    )}
                    <p></p>
                    {result && (
                        <div>
                           
                            {['B', 'C'].includes(calculationResults.severityClassResult) && (
                                <div>
                                    
                                    <div className="form-group">
                                        <label htmlFor="IndicationLength">Indication Length:</label>
                                        <p></p>
                                        <input
                                            type="number"
                                            step="0.1"
                                            min="0"
                                            className="form-control"
                                            id="IndicationLength"
                                            value={indicationLength}
                                            onChange={(e) => setIndicationLength(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setEnterPressed(true);
                                                }
                                            }}
                                        />
                                    </div>
                                    <p></p>

                                    {indicationLength && (  
                                        <div className="form-group" id="primaryStressSection">
                                            <label htmlFor="IsPrimaryTensileStress">
                                                Is the weld primary tensile stress and {dynamicValue && <span id="dynamicValue">{dynamicValue}</span>} {unit} from edge? 
                                            </label>
                                            <p></p>
                                            <select
                                                className="form-control"
                                                id="IsPrimaryTensileStress"
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setIsPrimaryTensileStressYes(value === "No" ? true : null);
                                                }}
                                               
                                            >
                                                <option value="">-- Please Make a Selection --</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                    )}

                                    {isPrimaryTensileStressYes && (
                                        <div className="form-group" id="multipleIndicationSection">
                                            <label htmlFor="MultipleIndication">
                                                Multiple Indication (Yes or No) - Only for B & C Classes:
                                            </label>
                                            <p></p>
                                            <select className="form-control"
                                                id="MultipleIndication"
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setMultipleIndication(value);
                                                }}
                                             
                                            >
                                                <option value="">-- Please Make a Selection --</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                    )}
                                    <p></p>
                                    
                                    {multipleIndication === 'Yes' && (
                                        <div className="form-group">
                                            <label htmlFor="numberOfSets" style={{ color: 'brown' }}>
                                               Please test & record the severity class, Indication length, and distance between indications for all indications found on the same weld.
                                                If there is an indication with severity class of A, the weld should be rejected.
                                                To continue to enter and use multiple indication page, 
                                            </label>
                                            <br />
                                            <br />
                                            <p></p>
                                            
                                            <p></p>
                                            <button
                                                className="button-styled"
                                                onClick={() => {
                                                    
                                                    navigate(`/MultipleDecision`);
                                                }}
                                            >
                                                Proceed to Multiple Indication Page
                                            </button>
                                        </div>
                                    )}

                                </div>
                            )}

                        </div>
                    )}
                    <p></p>
                    <div>
                        <button type="submit">Update Result</button>
                    </div>
                    
                    {message && (

                        <div>
                            <p style={{ color: 'red' }}>{message}</p>
                        </div>
                    )}
                   
                </form>
                
                <div style={{ 
                    position: "fixed",
                    top: "60%",
                    right: 0,
                    transform: "translateY(-50%)",
                    padding: "10px",
                    border: "1px solid #ccc",
                    overflow: "auto", 
                    wordWrap: "break-word", 
                    maxWidth: "225px", 
                }}>
                    <h2 style={{ textDecoration: 'underline' }}>Result</h2>
                    <p style={{ fontWeight: 'bold' }}>{result}</p>
                    
                    <p style={{ fontWeight: 'bold' }}>Severity Class: {calculationResults.severityClassResult}</p>
                    <h2>Decision</h2>
                    <p style={{ color: decisionResult === 'Accept' ? 'green' : decisionResult === 'Reject' ? 'red' : 'orange' }}>
                        {decisionResult}
                    </p>
                </div>
            </section>



        </Layout>
    );
};



export default D11Calculator;