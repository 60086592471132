import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';


const Header = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [username, setUsername] = useState('');

    const checkTokenExpiration = () => {
        const token = localStorage.getItem('token');
        const expirationTime = localStorage.getItem('tokenExpiration');

        console.log('Token:', token);
        console.log('Expiration Time:', expirationTime);
        console.log('Expiration Time:', expirationTime);

        if (token && expirationTime) {
            const currentTime = Date.now() / 1000; // Convert to seconds

            console.log('Current Time:', currentTime);

            if (currentTime > (parseInt(expirationTime, 10))) {
                // Token expired, perform logout
                console.log('Token Expired. Logging out...');
              
                handleLogout();
            }
        }
    };





    const handleLogout = async () => {
        try {
            const response = await fetch('https://radcliffe-ndt.com/api/logout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username }),
            });

            if (response.ok) {
                localStorage.removeItem('token');
                localStorage.removeItem('username');
                localStorage.removeItem('tokenExpiration');
                setIsLoggedIn(false);
                setUsername('');
                navigate('/Login');
            } else {
                // Handle logout failure
                console.error('Logout failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error during logout:', error);
            // Handle error
        }
    };

    useEffect(() => {
        // Check if user is logged in on component mount
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
            // Fetch and set the username if available
            const storedUsername = localStorage.getItem('username');
            if (storedUsername) {
                setUsername(storedUsername);
            }
            checkTokenExpiration(); // Check token expiration when the component mounts
        }

        // Check token expiration periodically (adjust the interval as needed)
        const expirationCheckInterval = setInterval(checkTokenExpiration, 60 * 1000);

        // Clear the interval on component unmount
        return () => clearInterval(expirationCheckInterval);
    }, [checkTokenExpiration]);

    const location = useLocation();

    return (
        <header style={{ width: '100%', top: 0, zIndex: 100 }}>
            <nav className="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3">
                <div className="container">
                    <img
                        src="/images/Radcliffe-logo.png"
                        alt="Company Logo"
                        width="85"
                        height="85"
                    />
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="navbar-collapse collapse d-sm-inline-flex justify-content-between">
                        <ul className="navbar-nav flex-grow-1">
                            <li className="nav-item">
                                <Link to="/" className="nav-link text-dark">
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/Services" className="nav-link text-dark">
                                    Services
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/ContactUs" className="nav-link text-dark">
                                    Contact Us
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/D11Calculator" className="nav-link text-dark">
                                    D1.1 Calculator
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to="/MultipleDecision"
                                    className="nav-link text-dark"
                                    data-route-shownumberofsetsform="true"
                                >
                                    Multiple Decision
                                </Link>
                            </li>
                        </ul>

                        <div className="d-flex align-items-center">
                            {isLoggedIn ? (
                                <div>
                                    <span style={{ color: 'black' }}  className="me-2">Welcome, {username} </span>
                                    <button className="nav-link btn btn-link" onClick={handleLogout} style={{ color: 'blue' }}>
                                        Logout
                                    </button>

                                    {location.pathname !== '/UserManagement' && (
                                        <Link to="/UserManagement" className="nav-link text-dark">
                                            User Management
                                        </Link>
                                    )}

                                </div>
                            ) : (
                                <Link to="/Login" className="nav-link" style={{ color: 'blue' }}>
                                    Login
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};
export default Header;
