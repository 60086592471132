import React from 'react';

import Layout from './Layout';

function Welcome() {
    return (
        
        
                <Layout>
                  <main style={{ backgroundImage: "url('/images/crane.jpg')", backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <section style={{ width: '75%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', }}>
                        <p>
                            Radcliffe Marine and Crane NDE is a California-based Nondestructive Testing (NDT) company serving various
                            industries worldwide since 1978. We are available 24/7 to help our customers with their needs to meet
                            compliance requirements, maximize safety, and increase productivity.
                        </p>
                        <p>Our technicians are trained, tested, and certified to SNT-TC-1A (ASNT) requirements.</p>

                        <p>Specializing in non-destructive testing (NDT), we commonly inspect the following items:</p>
                        <ul>
                            <li>Hoist/Lifting Equipment</li>
                            <li>Marine Inspection (repairs on weld and other structural inspection)</li>
                            <li>Port Cranes/Ship to Shore Cranes</li>
                            <li>Terminal Equipment</li>
                            <li>Tower Cranes</li>
                            <li>Container Ships</li>
                            <li>Weldments (AWS D1.1)</li>
                            <li>Pressure Vessels (ASME)</li>
                            <li>Castings/Forgings</li>
                            <li>Amusement Park Rides</li>
                        </ul>

                        <p>
                            We can design an inspection program to meet the needs of your company and vendors, or we can perform
                            inspections in accordance with your previously established program. Whatever needs you may have, we have
                            the capability to meet them.
                        </p>

                        <p>
                            Why us: At Radcliffe Marine and Crane NDE, we take pride in our unwavering commitment to efficiency and
                            excellence. Our team comprises seasoned inspectors who combine expertise with a friendly approach to
                            address your inspection requirements seamlessly. With our swift response system, we prioritize your
                            needs by deploying our inspectors to your location promptly. No more waiting for days or weeks - we
                            understand the value of your time. Additionally, our dedication to rapid turnaround ensures that you
                            receive comprehensive results within 24 hours. Trust Radcliffe Marine and Crane NDE for a seamless,
                            prompt, and professional inspection experience.
                        </p>
                    </section>
                </main>
           </Layout>
          

        
    );
}

export default Welcome;
