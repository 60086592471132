// UnauthorizedPage.jsx
import React, { useState, useEffect } from 'react';
import Layout from './Layout';

const UnauthorizedPage = () => {
    return (
        <Layout>
            <header style={{ backgroundColor: '#333', color: '#fff', textAlign: 'center', padding: '1em', marginTop: '0px' }}>
                <h1>LOGIN</h1>

            </header>
            <section style={{ maxWidth: '800px', margin: '20px auto', padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

        <div>
            <h2>Unauthorized Access</h2>
            <p>You don't have the required permissions to access this page.</p>
                </div>
            </section>
        </Layout>
    );
    
};

export default UnauthorizedPage;
