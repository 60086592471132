// ContactUs.jsx
import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';


const ContactUs = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [inquiries, setInquiries] = useState('pricing');
    const [comment, setComment] = useState('');
    const [capVal, setCapVal] = useState(null);
    const [siteKey, setSiteKey] = useState('');
    const [formError, setFormError] = useState('');

    useEffect(() => {
        
        const fetchConfig = async () => {
            try {
                const response = await fetch('https://radcliffe-ndt.com/api/config');
                const data = await response.json();
                setSiteKey(data.siteKey);
            } catch (error) {
                console.error('Error fetching config:', error);
            }
        };

        fetchConfig();
    }, []);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!firstName || !lastName || !email || !phone || !inquiries || !comment || !capVal) {
            setFormError('Please fill out all fields.');
            return;
        }

        setFormError('');

    const formData = {
        firstName,
        lastName,
        email,
        phone,
        inquiries,
        comment,
    };

    try {
        const response = await axios.post('https://radcliffe-ndt.com/api/submit-form', formData);
        console.log(response.data); 
    } catch (error) {
        console.error(error);
    }

    setCapVal(null);
    };
    
    return (
        <Layout>
          
                <header style={{ backgroundColor: '#333', color: '#fff', textAlign: 'center', padding: '1em', marginTop: '0px' }}>
                    <h1>RADCLIFFE COMPANIES INC.</h1>
                    <p>13337 SOUTH ST. SUITE 361, CERRITOS, CA 90703, U.S.A</p>
                    <p>PHONE: 562-437-7829</p>
                </header>

                <section style={{ maxWidth: '800px', margin: '20px auto', padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h2>Contact Us</h2>
                <form action="https://radcliffe-ndt.com/api/submit-form" method="post" style={{ width: '100%', display: 'grid', gap: '10px' }}>

                        <div style={{ display: 'grid', gap: '10px', textAlign: 'left' }}>
                            <label htmlFor="firstName" style={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>First Name:</label>
                            <input type="text" id="firstName" name="firstName" required />
                        </div>

                        <div style={{ display: 'grid', gap: '10px', textAlign: 'left' }}>
                            <label htmlFor="lastName" style={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>Last Name:</label>
                            <input type="text" id="lastName" name="lastName" required />
                        </div>

                        <div style={{ display: 'grid', gap: '10px', textAlign: 'left' }}>
                            <label htmlFor="email" style={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>Email Address:</label>
                            <input type="email" id="email" name="email" required />
                        </div>

                        <div style={{ display: 'grid', gap: '10px', textAlign: 'left' }}>
                            <label htmlFor="phone" style={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>Phone Number:</label>
                            <input type="tel" id="phone" name="phone" required />
                        </div>

                        <div style={{ display: 'grid', gap: '10px', textAlign: 'left' }}>
                            <label htmlFor="inquiries" style={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>Inquiries:</label>
                            <select id="inquiries" name="inquiries">
                                <option value="pricing">Pricing</option>
                                <option value="service">Service</option>
                            </select>
                        </div>

                        <div style={{ display: 'grid', gap: '10px', textAlign: 'left' }}>
                            <label htmlFor="comment" style={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>Comment:</label>
                            <textarea id="comment" name="comment" rows="4" required></textarea>
                        </div>
                    {formError && (
                        <div style={{ color: 'red', textAlign: 'center' }}>{formError}</div>
                    )}
                    <div>
                        {siteKey && (
                            <ReCAPTCHA
                                sitekey={siteKey}
                                onChange={(val) => setCapVal(val)}
                            />
                        )}
                    </div>

                    <button
                        type="submit"
                        style={{
                            backgroundColor: '#333',
                            color: '#fff',
                            padding: '10px',
                            border: 'none',
                            cursor: 'pointer',
                            marginTop: '10px',
                        }}
                        disabled={!capVal}
                    >
                        Submit
                    </button>
                    </form>
                </section>




           
            </Layout>
    );
};

export default ContactUs;
