import React from 'react';
import Layout from './Layout';

function Error() {
    return (
        <Layout>
            <header style={{ backgroundColor: '#333', color: '#fff', textAlign: 'center', padding: '1em', marginTop: '0px' }}>
                <h1>Contact Form Error</h1>
            </header>

            <section style={{ maxWidth: '800px', margin: '20px auto', padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', textAlign: 'left' }}>

                <h2>Error Submitting Form.</h2>
                Please try again or call our office at (562) 437-7829.
            </section>

        </Layout>
    );
}
export default Error;