// Footer.jsx
import React from 'react';

const Footer = () => (
    <footer className="border-top footer text-muted">
        <div className="container-fluid">
            &copy; 2023 - Radcliffe UTCalculatorV3 - Authorized User Only |{' '}
            
        </div>
        <div>
            Radcliffe Marine and Crane NDE is a service of Radcliffe Companies Inc. All content &copy; Radcliffe Companies Inc.
        </div>
    </footer>
);

export default Footer;
