import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Layout from './Layout'; 

const ModifyUser = () => {
    const { userId } = useParams();
    const [userData, setUserData] = useState({
        UserName: '',
        Password: '',
        ClaimType: '',
        Company: '',
        Department: '',
        EmployeeNumber: '',
    });
    
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`https://radcliffe-ndt.com/api/getUser/${userId}`);
                setUserData(response.data);
            } catch (error) {
                console.error('Error fetching user:', error);
                setError('Error fetching user. Please try again.');
            }
        };

        fetchUser();
    }, [userId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`https://radcliffe-ndt.com/api/modifyUser/${userId}`, userData);
            setMessage(response.data.message); 
            setError(''); 
            setUserData({
                UserName: '',
                Password: '',
                ClaimType: '',
                Company: '',
                Department: '',
                EmployeeNumber: '',
            });
        } catch (error) {
            console.error('Error modifying user:', error);
            setError('Error modifying user. Please try again.');
        }
    };

    return (
        <Layout>
            <header style={{ backgroundColor: '#333', color: '#fff', textAlign: 'center', padding: '1em', marginTop: '0px' }}>
                <h1>Edit User Information</h1>
            </header>

            <section style={{ maxWidth: '800px', margin: '20px auto', padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', textAlign: 'left' }}>

        
                {error && <p style={{ color: 'red' }}>{error}</p>}
                {message && <p style={{ color: 'green' }}>{message}</p>}
                <form method="post" onSubmit={handleSubmit}>
                   
                    <div className="form-group">
                        <label>UserName:</label>
                        <input type="text" name="UserName" value={userData.UserName} onChange={handleChange} className="form-control" />
                    </div>
                    <p></p>
                    <div className="form-group">
                        <label>Password:</label>
                        <input type="text" name="Password" onChange={handleChange} className="form-control" />
                    </div>
                    <p></p>
                    <div className="form-group">
                        <label>ClaimType:</label>
                        <input type="text" name="ClaimType" value={userData.ClaimType} onChange={handleChange} className="form-control" />
                    </div>
                    <p></p>
                    <div className="form-group">
                        <label>Company:</label>
                        <input type="text" name="Company" value={userData.Company} onChange={handleChange} className="form-control" />
                    </div>
                    <p></p>
                    <div className="form-group">
                        <label>Department:</label>
                        <input type="text" name="Department" value={userData.Department} onChange={handleChange} className="form-control" />
                    </div>
                    <p></p>
                    <div className="form-group">
                        <label>EmployeeNumber:</label>
                        <input type="text" name="EmployeeNumber" value={userData.EmployeeNumber} onChange={handleChange} className="form-control" />
                    </div>
                    
                    <p></p>
                    <div className="form-group">
                        <input type="submit" value="Save" className="btn btn-primary" />
                    </div>
                </form>
                <p></p>
                <div>
                    <a href="/UserManagement">Back to User Management</a>
                </div>
            </section>

        </Layout>
    );
};

export default ModifyUser;
