import React, { useState } from 'react';
import axios from 'axios';
import Layout from './Layout'; 

const CreateUser = () => {
    const [userData, setUserData] = useState({
        UserName: '',
        Password: '',
        ClaimType: '',
        Company: '',
        Department: '',
        EmployeeNumber: '',
    });
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handlePasswordChange = (e) => {
        const { value } = e.target;
        setUserData((prevData) => ({ ...prevData, Password: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (
            !userData.UserName ||
            !userData.Password ||
            !userData.ClaimType ||
            !userData.Company ||
            !userData.Department ||
            !userData.EmployeeNumber
        ) {
            setError('Please fill in all fields.');
            return;
        }

        try {
            const response = await axios.post('https://radcliffe-ndt.com/api/createUser', userData);
            setMessage(response.data.message); 
            setError(''); 

            
            setUserData({
                UserName: '',
                Password: '',  
                ClaimType: '',
                Company: '',
                Department: '',
                EmployeeNumber: '',
            });


        } catch (error) {
            console.error('Error creating user:', error);
            setError('Error creating user. Please try again.'); 
        }
    };

    return (
        <Layout>
            <header style={{ backgroundColor: '#333', color: '#fff', textAlign: 'center', padding: '1em', marginTop: '0px' }}>
                <h1>Create User</h1>
            </header>

            <section style={{ maxWidth: '800px', margin: '20px auto', padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', textAlign: 'left' }}>
           
                {error && <p style={{ color: 'red' }}>{error}</p>}
                {message && <p style={{ color: 'green' }}>{message}</p>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>UserName:</label>
                    <input type="text" name="UserName" value={userData.UserName} onChange={handleChange} className="form-control" />
                </div>
                <p></p>
                    <div className="form-group">
                        <label>Password:</label>
                        <input type="password" name="Password" value={userData.Password} onChange={handlePasswordChange} className="form-control" />
                    </div>
                <p></p>
                <div className="form-group">
                    <label>ClaimType:</label>
                    <input type="text" name="ClaimType" value={userData.ClaimType} onChange={handleChange} className="form-control" />
                </div>
                <p></p>
                <div className="form-group">
                    <label>Company:</label>
                    <input type="text" name="Company" value={userData.Company} onChange={handleChange} className="form-control" />
                </div>
                <p></p>
                <div className="form-group">
                    <label>Department:</label>
                    <input type="text" name="Department" value={userData.Department} onChange={handleChange} className="form-control" />
                </div>
                <p></p>
                <div className="form-group">
                    <label>EmployeeNumber:</label>
                    <input type="text" name="EmployeeNumber" value={userData.EmployeeNumber} onChange={handleChange} className="form-control" />
                </div>
               
                <p></p>
                <div className="form-group">
                    <button type="submit">Create User</button>
                </div>
                </form>
                <p></p>
                <div>
                    <a href="/UserManagement">Back to User Management</a>
                </div>
           
            </section>

        </Layout>
    );
};

export default CreateUser;
