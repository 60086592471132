
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom'; 
import Layout from './Layout'; 

const DeleteUser = () => {
    const { userId } = useParams();
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [message, setMessage] = useState(''); 
    const navigate = useNavigate(); 

    
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`https://radcliffe-ndt.com/api/getUser/${userId}`);
                setUserData(response.data);
            } catch (error) {
                console.error('Error fetching user:', error);
                setError('Error fetching user. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, [userId]);

    const handleDelete = async () => {
        try {
            await axios.delete(`https://radcliffe-ndt.com/api/deleteUser/${userId}`);

            setMessage(`${userData.UserName} successfully deleted`);
             
            setTimeout(() => {
                
                navigate('/UserManagement');
            }, 1000);

        } catch (error) {
            console.error('Error deleting user:', error);
            setError('Error deleting user. Please try again.');
        }
    };

    return (
         <Layout>
            <header style={{ backgroundColor: '#333', color: '#fff', textAlign: 'center', padding: '1em', marginTop: '0px' }}>
                <h1>Delete User</h1>
            </header>

            <section style={{ maxWidth: '800px', margin: '20px auto', padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', textAlign: 'left' }}>

      
            {loading ? (
                <p>Loading user...</p>
            ) : (

                    <div>
                        {message && <p style={{ color: 'green', fontWeight: 'bold' }}>{message}</p>}
                        <p style={{ color: 'red', fontWeight: 'bold' }}>Are you sure you want to delete user {userData.UserName}?</p>

                    <button onClick={handleDelete}>Yes, delete user</button>
                    <p style={{ color: 'red' }}>{error}</p>
                </div>
            )}
            <Link to="/UserManagement">Back to User Management</Link>
       </section>

        </Layout>
    );
};

export default DeleteUser;
