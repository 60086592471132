// Services.jsx

import React from 'react';
import Layout from './Layout'; 

function Services() {
    return (
        <Layout>
            <header style={{ backgroundColor: '#333', color: '#fff', textAlign: 'center', padding: '1em', marginTop: '0px' }}>
                <h1>Our Services</h1>
            </header>

            <section style={{ maxWidth: '800px', margin: '20px auto', padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', textAlign: 'left' }}>

                <p>We are available for inspections in the United States and worldwide, 24 hours, 7 days a week. In addition, we can be in your location within 24 hours or less, and we work around your schedule to minimize downtime.</p>

                <h2>Traditional NDT Services</h2>
                <ul>
                    <li>Magnetic Particle Testing</li>
                    <li>Ultrasonic Testing</li>
                    <li>Liquid Penetrant Testing</li>
                    <li>Visual Testing</li>
                </ul>

                <h2>Other Services</h2>
                <ul>
                    <li>Quality Assurance Inspection</li>
                    <li>Brinell Hardness Testing</li>
                    <li>AWS Certified Welding Inspector</li>
                </ul>

                <p>Other NDT services available upon request.</p>

                <h2>Port Cranes and Terminal Services</h2>
                <p>RADCLIFFE MARINE AND CRANE has performed major structural inspections and presently performs maintenance NDT inspections for crane weld repairs, spreaders, RTG, top picks, and cyclic inspections of twistlocks for crane shops in Port of Long Beach, Port of Los Angeles, and Port of Oakland.</p>
                <h2>Tower, Self Erect, and Mobile Cranes</h2>
                <p>RADCLIFFE MARINE AND CRANE has extensive knowledge in performing pre-erection and annual NDT inspections for our clients as required by Cal OSHA.</p>

                <h2>Gantry/Overhead Cranes</h2>
                <p>RADCLIFFE MARINE AND CRANE provides periodic NDT inspections as required by OSHA.</p>

                <h2>Metal and Machinery Fabrication Shops</h2>
                <p>RADCLIFFE MARINE AND CRANE provides NDT inspection for fabrication shops all over Southern California since the 1980s. Our inspection is tailored to our client's procedures (ASME, ASTM, AWS, etc.).</p>

                <h2>Marine Services</h2>
                <p>RADCLIFFE MARINE AND CRANE provides structural NDT inspection for the maritime industries. Our service includes thickness measurement, weld repairs, engine room crane, provision crane, and other marine structural components.</p>
            </section>

        </Layout>
    );
}

export default Services;
