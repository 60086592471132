import React, { useState, useEffect } from 'react';
import './MultipleDecision.css';
import Layout from './Layout';

const MultipleDecision = () => {
    const [rowCount, setRowCount] = useState(0); 
    const [measurementUnit, setMeasurementUnit] = useState('inches');
    const [checkBoxes, setCheckBoxes] = useState(Array(rowCount).fill(false));
    const [severityClass1, setSeverityClass1] = useState(Array(rowCount).fill(''));
    const [length1, setLength1] = useState(Array(rowCount).fill(null));
    const [severityClass2, setSeverityClass2] = useState(Array(rowCount).fill(''));
    const [length2, setLength2] = useState(Array(rowCount).fill(null));
    const [distanceBetweenIndications, setDistanceBetweenIndications] = useState(
        Array(rowCount).fill(null)
    );
    const [resultData, setResultData] = useState(Array(rowCount).fill(null));


    const handleRowCountChange = (e) => {
        const newRowCount = parseInt(e.target.value, 10);
        setRowCount(newRowCount);
    };

    useEffect(() => {
        
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
       
        
        const requestData = {
            data: [...Array(rowCount).keys()].map((index) => ({
                SeverityClass1: severityClass1[index],
                SeverityClass2: severityClass2[index],
                Length1: parseFloat(length1[index]),
                Length2: parseFloat(length2[index]),
                DistanceBetweenIndications: parseFloat(distanceBetweenIndications[index]),
                MeasurementUnit: measurementUnit,
            })),
        };

        try {
            const response = await fetch('https://radcliffe-ndt.com/api/multipleIndicationCalc', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            if (response.ok) {
                const resultDataFromApi = await response.json();
                setResultData(resultDataFromApi.MultiResults || []);
            } else {
               console.error('API Error:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Unexpected Error:', error);
        }
    };
    

    return (
        <Layout>
        <div>
            <header>
            <h1 className="display-1.1">AWS D1.1 Multiple Discontinuities Calculator</h1>
            <hr />
        </header>
            <section>
            <form onSubmit={handleSubmit}>
                <div className="text-danger"></div>
                <div className="row">
                    <div className="col-3">
                        <label htmlFor="measurementUnit">Measurement Unit:</label>
                    </div>
                    <div className="col-3">
                        <select
                            className="data-input"
                            id="measurementUnit"
                            name="measurementUnit"
                            value={measurementUnit}
                            onChange={(e) => setMeasurementUnit(e.target.value)}
                        >
                            <option value="inches">Inches</option>
                            <option value="millimeters">Millimeters</option>
                        </select>
                    </div>
                </div>
                <p></p>
                <div className="row">
                    <div className="col-3">
                        <label htmlFor="rowCount">Number of Sets:</label>
                        
                    </div>
                    <div className="col-3">
                        <input
                            type="number"
                            id="rowCount"
                            name="rowCount"
                            value={rowCount}
                            onChange={handleRowCountChange}
                        />
                    </div>
                </div>
                Choose how many sets of multiple indications you need: (Hint: One set is to compare two indications.)
                <p></p>
               <table>
                    <thead>
                        <tr>
                            <th>Select to Calculate</th>
                            <th>Severity Class (1st Weld)</th>
                            <th>Length of Indication</th>
                            <th>Severity Class (2nd Weld)</th>
                            <th>Length of Indication</th>
                            <th>Distance Between Indications</th>
                            <th>Decision</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(rowCount)].map((_, index) => (
                            <tr key={index} className="data-row">
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={checkBoxes[index] || false}
                                        onChange={() => {
                                            const newCheckBoxes = [...checkBoxes];
                                            newCheckBoxes[index] = !checkBoxes[index];
                                            setCheckBoxes(newCheckBoxes);
                                        }}
                                    />
                                </td>
                                <td>
                                    <select
                                        className="data-input"
                                        value={severityClass1[index] || ''}
                                        onChange={(e) => {
                                            const newSeverityClass1 = [...severityClass1];
                                            newSeverityClass1[index] = e.target.value;
                                            setSeverityClass1(newSeverityClass1);
                                        }}
                                    >
                                        <option value="">-- Select 1st Severity Class --</option>
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="C">C</option>
                                    </select>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        className="data-input"
                                        value={length1[index] || ''}
                                        onChange={(e) => {
                                            const newLength1 = [...length1];
                                            newLength1[index] = e.target.value;
                                            setLength1(newLength1);
                                        }}
                                        min="0.01"
                                        step="0.01"
                                        placeholder=""
                                    />
                                </td>

                                <td>
                                    <select
                                        className="data-input"
                                        value={severityClass2[index] || ''}
                                        onChange={(e) => {
                                            const newSeverityClass2 = [...severityClass2];
                                            newSeverityClass2[index] = e.target.value;
                                            setSeverityClass2(newSeverityClass2);
                                        }}
                                    >
                                        <option value="">-- Select 2nd Severity Class --</option>
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="C">C</option>
                                    </select>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        className="data-input"
                                        value={length2[index] || ''}
                                        onChange={(e) => {
                                            const newLength2 = [...length2];
                                            newLength2[index] = e.target.value;
                                            setLength2(newLength2);
                                        }}
                                        min="0.01"
                                        step="0.01"
                                        placeholder=""
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        className="data-input"
                                        value={distanceBetweenIndications[index] || ''}
                                        onChange={(e) => {
                                            const newdistanceBetweenIndications = [...distanceBetweenIndications];
                                            newdistanceBetweenIndications[index] = e.target.value;
                                            setDistanceBetweenIndications(newdistanceBetweenIndications);
                                        }}
                                        min="0.01"
                                        step="0.01"
                                        placeholder=""
                                    />
                                </td>

                                <td>
                                   {checkBoxes[index] && (
                                        <div>
                                            Result: {resultData && resultData[index] !== undefined ? resultData[index] : 'Not Calculated'}
        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <p></p>
                
                <button type="submit" className="btn btn-primary">
                    Update Result
                </button>
                </form>
                </section >

        </div>
            </Layout> 
    );

};

export default MultipleDecision;
